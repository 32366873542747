<template>
  <div class="main-contents code">
  <div class="tit">적립 포인트 관리</div>
  <div class="search-box">
    <SelectComp v-model="srchFilter.pointTargetDivCd" class="wd01 custom-width" :list="'D:수요기업,S:공급기업,T:개인기술회원'" :isAll="true" title="적립 포인트 대상" />
    <nbsp/>
    <InputComp v-model="srchFilter.srchTxt" placeholder="적립포인트 항목명" @keyup.enter="getPointCodeList('1')"/>
    <nbsp/>
    <!-- <div class="select_wrap wd01"> -->
    <SelectComp class="wd01" list="Y:예,N:아니오" v-model="srchFilter.useYn" :isAll="true" title="사용여부" />
    <nbsp/>
    <img class="search_btn" src="/images/admin/search_btn.png" alt="검색버튼" @click="getPointCodeList('1')"/>
    <div class="sub_btn" @click="$router.push('MAN934M02')">
    + 코드등록
    </div>
  </div>
  <!-- 적립포인트코드 리스트 -->
  <div class="Board type3">
  <table class="Board_type3 admin">
    <colgroup>
      <col width="4%">
      <col width="10%">
      <col width="17%">
      <col width="33%">
      <col width="11%">
      <col width="8%">
      <col width="7%">
      <col width="10%">
    </colgroup>
    <thead>
      <tr>
        <th>NO</th>
        <th>적립 포인트 코드</th>
        <th>적립 포인트 항목명</th>
        <th>적립 포인트 항목설명</th>
        <th>적립 포인트 대상구분</th>
        <th>적립 포인트</th>
        <th>사용여부</th>
        <th>적립 항목 관리</th>
      </tr>
    </thead>
    <tbody v-if="pointCodeList.length > 0">
      <tr v-for="(row,rowIdx) in pointCodeList" :key="rowIdx">
        <td class="score">{{(pageInfo.pageUnit * (pageInfo.pageIndex-1)) + rowIdx + 1}}</td>
        <td class="score">{{row.pointSaveCd}}</td>
        <td class="score btn_cursor" @click="modify(row.pointSaveCd)">{{row.pointSaveCdNm}} &#9654;</td>
        <td class="score">{{row.pointSaveCdCont}}</td>
        <td class="score">
          <SelectComp type="text" :value="row.pointSaveTargetDivCd" cdId="PRO130"/>
        </td>
        <td class="score" v-if="row.pointSaveAmt == '' || row.pointSaveAmt == null">
          0P
        </td>
        <td class="score" v-else>
          {{row.pointSaveAmt+'P'}}
        </td>
        <td class="score">
          <SelectComp type="text" :value="row.useYn" list="Y:예,N:아니오"/>
        </td>
        <td class="score btn" @click="viewPointItemHistory(row)"><div class="btn">항목관리 &#9654;</div></td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr>
        <td colspan="8" class="none">등록된 적립 포인트 코드가 없습니다!</td>
      </tr>
    </tbody>

  </table>
</div>
<!-- 페이징부분 -->
<pagingComp :pageInfo="pageInfo" @page-click="goPage"/>
</div>
</template>
<script>
import pagingComp from '@/components/PagingComp.vue';

export default {
  data(){
    return{
      srchFilter : {
        useYn : '',
        pageIndex: 1,
        srchTxt: '',
        pointTargetDivCd : '',
      },

      pointCodeList: [],

      pageInfo : {},
    }
  },

  components: {
    pagingComp
  },

  mounted(){
    var keys = Object.keys(this.srchFilter);
    for(var key in this.$route.params){
      if(keys.indexOf(key) >= 0){
        this.srchFilter[key] = this.$route.params[key];
      }
    }
    this.getPointCodeList()
  },

  methods: {
    //적립 포인트 코드 목록 조회
    getPointCodeList(div){
      var param = this.srchFilter;
      param.pageUnit = '25';
      param.pageSize = '10';
      if(div) param.pageIndex = 1;

      this.$.httpPost('/api/main/adm/point/getPointCodeList',param)
        .then(res=>{
          this.pointCodeList = res.data.pointCodeList;
          this.pageInfo = res.data.pageInfo;
        }).catch(this.$.httpErrorCommon);
    },

    //페이징 이동
    goPage(page){
      this.srchFilter.pageIndex = page;
      this.getPointCodeList();
    },

    modify(pointSaveCd){
      // console.log('pointSaveCd',pointSaveCd);
      this.$router.push({name: 'MAN934M02', params: {pointSaveCd : pointSaveCd}});
    },
    viewPointItemHistory(row){
      var params = {
        pointSaveCd : row.pointSaveCd,
        pointSaveCdApplyVer : row.pointSaveCdApplyVer,
        pointSaveCdNm : row.pointSaveCdNm,
        caller : {
          name : this.$route.name,
          params : this.srchFilter
        }
      }
      this.$router.push({name: 'MAN934M03', params});
    }
  }
}
</script>